import { BreakpointsOptions, createTheme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

import palette from "./palette";
import typography from "./typography";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    midxs: true;
    sm: true;
    midsm: true;
    md: true;
    midmd: true;
    lg: true;
    midlg: true;
    xl: true;
  }
}

interface ExtendedBreakpointOptions extends BreakpointsOptions {
  midxs: number;
  midsm: number | any;
  midmd: number;
  midlg: number;
}

const customBreakpointValues = {
  values: {
    xs: 0,
    midxs: 600,
    sm: 700,
    midsm: 800,
    md: 900,
    midmd: 1025,
    lg: 1200,
    midlg: 1300,
    xl: 1536,
  },
};

const breakpoints: any = createBreakpoints({ ...customBreakpointValues });

export const lightTheme = createTheme({
  palette,
  typography,

  breakpoints: {
    ...customBreakpointValues,
  } as ExtendedBreakpointOptions,
  components: {
    // Name of the component
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          lineHeight: "20px",
          fontSize: "14px",
          fontWeight: 400,
          [breakpoints.down("midsm")]: {
            fontSize: "12px",
          },
          // borderRadius: 0,
          boxShadow: "unset",
          textTransform: "uppercase",
          letterSpacing: "0.02em",
          "&.Mui-disabled": {
            backgroundColor: palette.action.disabled,
            color: palette.common.white,
          },
          "& .MuiLoadingButton-loadingIndicator": {
            color: palette.common.white,
          },
          disableRipple: false,
        },

        outlined: {
          borderColor: palette.primary.main,
          color: palette.common.black,
          backgroundColor: palette.common.white,
          "&:hover": {
            borderColor: palette.primary.main,
            backgroundColor: palette.common.white,
          },
        },
        contained: {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.primary.main,
          },
        },
        containedSizeLarge: {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          padding: "10px 24px",
        },
        containedSizeMedium: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          padding: "6px 24px",
        },
        containedSizeSmall: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          padding: "6px 12px",
        },
        outlinedSizeLarge: {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          padding: "10px 24px",
        },
        outlinedSizeMedium: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          padding: "6px 24px",
        },
        outlinedSizeSmall: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          padding: "6px 12px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          pointerEvents: "none",
        },
        message: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiOutlinedInput-root": {
    //         "& fieldset": {
    //           color: palette.common.black,
    //           backgroundColor: palette.common.white,
    //           borderColor: palette.primary.main,
    //         },
    //         "&:hover fieldset": {
    //           color: palette.common.black,
    //           backgroundColor: palette.common.white,
    //           borderColor: palette.primary.main,
    //         },
    //         "&.Mui-focused fieldset": {
    //           color: palette.common.black,
    //           backgroundColor: palette.common.white,
    //           borderColor: palette.primary.main,
    //         },
    //       },
    //     },
    //   },
    // },
  },
});

const darkTheme = createTheme({
  palette: {
    ...palette,
    mode: "dark", // Enable dark mode
  },
  typography,
  breakpoints: {
    ...customBreakpointValues,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.common.white,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.black,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.common.white, // Change this to your desired color
        },
      },
    },
  },
});

export default darkTheme;

// export default lightTheme;
