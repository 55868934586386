const AppRoutes = {
  // STATIONINFO: "/",
  DASHBOARD: "/dashboard",
  STATIONINFO: "/stationInfo",
  SCANQR: "/",
  // LOGIN: "/login",
  USERDETAILFORM: "/userForm",
  CONNECTCAR: "/connectcar",
  CHARGINGSTATION: "/chargingstation",
  INVOICE: "/invoice",
  CHARGINGSTART: "/chargingStart",
};

export default AppRoutes;
