import Loadable from "react-loadable";

import { Spinner } from "components/Loader";

// export const Login = Loadable({
//   loader: () => import("pages/Auth/Login"),
//   loading: () => <Spinner />,
// });

export const QRScan = Loadable({
  loader: () => import("pages/ScanOR"),
  loading: () => <Spinner />,
});

export const StationInfo = Loadable({
  loader: () => import("pages/StationInfo"),
  loading: () => <Spinner />,
});

export const UserDetailForm = Loadable({
  loader: () => import("pages/UserDetailForm"),
  loading: () => <Spinner />,
});

export const ConnectCar = Loadable({
  loader: () => import("pages/ConnectCar"),
  loading: () => <Spinner />,
});

export const ChargingStart = Loadable({
  loader: () => import("pages/ChargingStart"),
  loading: () => <Spinner />,
});

export const ChargingStation = Loadable({
  loader: () => import("pages/ChargingStation"),
  loading: () => <Spinner />,
});

export const Invoice = Loadable({
  loader: () => import("pages/Invoice"),
  loading: () => <Spinner />,
});
